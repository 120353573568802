'use client'

import { Box, IconButton, IconEdit, Link, spacing } from '@fortum/elemental-ui'

import { serverEnvs } from '@/shared/envs'

type OpenInContentfulProps = {
  id: string
}

export const OpenInContentful = ({ id }: OpenInContentfulProps) => {
  const environment = serverEnvs.CONTENTFUL_ENVIRONMENT ?? 'test'

  return (
    <Box position="fixed" right={0} bottom={0} margin={spacing.xxs}>
      <Link
        href={`https://app.contentful.com/spaces/bs7i8p83z7um/environments/${environment}/entries/${id}`}
      >
        <IconButton status="plain" icon={<IconEdit size={30} />} aria-label="Open in Contentful" />
      </Link>
    </Box>
  )
}
